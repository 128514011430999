if (typeof BroadcastChannel === 'undefined') {
    class BroadcastChannelPolyfill {
        constructor(channel) {
            this.channel = channel;
            this.listeners = [];
        }

        postMessage(message) {
            this.listeners.forEach(listener => listener({ data: message }));
        }

        addEventListener(_, listener) {
            this.listeners.push(listener);
        }

        close() {
            this.listeners = [];
        }
    }

    window.BroadcastChannel = BroadcastChannelPolyfill;
}
