export const registerFireBaseServiceWorker = () => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('firebase-messaging-sw.js')
        .then(function (registration) {
          return registration.scope;
        })
        .catch(function (err) {
          return err;
        });
    }

    // console.log("register")

    if ('serviceWorker' in navigator) {
      // Global service worker
      navigator.serviceWorker
        .register('/service-worker.js')
        .then((registration) => {
          // console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch((error) => {
          // console.error('Service Worker registration failed:', error);
        });
    }
  };